import {
  Col as FlexCol,
  Grid as FlexGrid,
  Row as FlexRow
} from 'react-flexbox-grid';

import styled, { createGlobalStyle } from 'styled-components';

import { Breakpoint, Spacing } from '@atomic/constants';

import { GridConfigs } from './grid-configs';

const hideInXSmall = `
@media only screen and (max-width: ${Breakpoint.XSmall}) {
  display: none;
}
`;

const hideInSmall = `
@media only screen and (max-width: ${Breakpoint.Small}) and (min-width: calc(${Breakpoint.XSmall} + 1px)) {
  display: none;
}
`;
const hideInMedium = `
@media only screen and (max-width: ${Breakpoint.Medium}) and (min-width: calc(${Breakpoint.Small} + 1px) ) {
  display: none;
}
`;

const hideInLarge = `
@media only screen and (max-width: ${Breakpoint.Large}) and (min-width: calc(${Breakpoint.Medium} + 1px) ) {
  display: none;
}
`;

const hideInXLarge = `
@media only screen and (min-width: ${Breakpoint.XLarge}) {
  display: none;
}
`;

export interface RowProps {
  mb?: boolean;
  mt?: boolean;
  noGutter?: boolean;
  overflowHidden?: boolean;
  hAlign?: string;
}

export const Row = styled(FlexRow)<RowProps>`
  margin-bottom: ${(props) => (props.mb ? Spacing.SemiSmall : '0px')};
  margin-top: ${(props) => (props.mt ? Spacing.SemiSmall : '0px')};
  ${(props) => props.overflowHidden && 'overflow: hidden;'};

  ${(props: RowProps) =>
    props.hAlign &&
    `
      justify-content: ${props.hAlign};
    `}
`;

const gutterWidth = `${Spacing.Nano}`;
interface ColProps {
  hAlign?: string;
  vAlign?: string;
  mb?: boolean;
  mt?: boolean;
  flexCol?: boolean;
  noGutter?: boolean;
  xsHidden?: boolean;
  smHidden?: boolean;
  mdHidden?: boolean;
  lgHidden?: boolean;
  xlHidden?: boolean;
  rowLike?: boolean;
}
export const Col = styled(FlexCol)<ColProps>`
  margin-bottom: ${(props) => (props.mb ? Spacing.SemiSmall : '0px')};
  margin-top: ${(props) => (props.mt ? Spacing.SemiSmall : '0px')};
  ${(props: GridProps) => props.noGutter && 'padding: 0;'};

  ${(props: ColProps) =>
    props.rowLike &&
    `
    box-sizing: border-box;
    display: flex;
    flex-flow: inherit;
    flex-direction: row;
    `}

  ${(props: ColProps) =>
    props.hAlign &&
    `
      display: flex;
      flex-direction: column;
      align-items: ${props.hAlign};
      text-align: ${props.hAlign};
    `}

  ${(props: ColProps) =>
    props.vAlign &&
    `
      display: flex;
      flex-direction: column;
      justify-content: ${props.vAlign};
    `}

    ${(props: ColProps) =>
    props.flexCol &&
    `
      display: inline-flex;
      flex-direction: column;
      flex: auto;
      flex-basis: 100%;
    `}

  ${(props: ColProps) => props.xsHidden && hideInXSmall}

  ${(props: ColProps) => props.smHidden && hideInSmall}

    ${(props: ColProps) => props.mdHidden && hideInMedium}

    ${(props: ColProps) => props.lgHidden && hideInLarge}

        ${(props: ColProps) => props.xlHidden && hideInXLarge}
`;

interface GridProps {
  noGutter?: boolean;
  expanded?: boolean;
  xsHidden?: boolean;
  smHidden?: boolean;
  mdHidden?: boolean;
  lgHidden?: boolean;
  xlHidden?: boolean;
}

export const Grid = styled(FlexGrid)<GridProps>`
  padding: 0
    ${(props: GridProps) => (props.noGutter ? '0' : `${gutterWidth}px`)};
  width: ${(props: GridProps) => props.expanded && '100%'};

  ${(props: GridProps) => props.xsHidden && hideInXSmall}

  ${(props: GridProps) => props.smHidden && hideInSmall}

  ${(props: GridProps) => props.mdHidden && hideInMedium}

    ${(props: GridProps) => props.lgHidden && hideInLarge}
        ${(props: GridProps) => props.xlHidden && hideInXLarge}
`;

export const GridStyled = createGlobalStyle`
  .container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: ${GridConfigs.xs.containerWidth};
    max-width: ${GridConfigs.xs.containerMaxWidth};
    padding-right: ${GridConfigs.xs.gap};
    padding-left: ${GridConfigs.xs.gap};
  }

  .row {
    margin-right: -${GridConfigs.xs.gap};
    margin-left: -${GridConfigs.xs.gap};
  }

  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-offset-0,
  .col-xs-offset-1,
  .col-xs-offset-2,
  .col-xs-offset-3,
  .col-xs-offset-4,
  .col-xs-offset-5,
  .col-xs-offset-6,
  .col-xs-offset-7,
  .col-xs-offset-8,
  .col-xs-offset-9,
  .col-xs-offset-10,
  .col-xs-offset-11,
  .col-xs-offset-12 {
    padding-right: ${GridConfigs.xs.gap};
    padding-left: ${GridConfigs.xs.gap};
  }

  @media only screen and (min-width: 576px) {
    .container {
      width: ${GridConfigs.sm.containerWidth};
      max-width: ${GridConfigs.sm.containerMaxWidth};
      padding-right: ${GridConfigs.sm.gap};
      padding-left: ${GridConfigs.sm.gap};
    }

    .row {
      margin-right: -${GridConfigs.sm.gap};
      margin-left: -${GridConfigs.sm.gap};
    }

    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-offset-0,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12{
      padding-right: ${GridConfigs.sm.gap};
    padding-left: ${GridConfigs.sm.gap};
    }
  }

  @media only screen and (min-width: 768px) {
    .container {
      width: ${GridConfigs.md.containerWidth};
      max-width: ${GridConfigs.md.containerMaxWidth};
      padding-right: ${GridConfigs.md.gap};
      padding-left: ${GridConfigs.md.gap};
    }

    .row {
      margin-right: -${GridConfigs.md.gap};
      margin-left: -${GridConfigs.md.gap};
    }

    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-offset-0,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12{
      padding-right: ${GridConfigs.md.gap};
    padding-left: ${GridConfigs.md.gap};
    }
  }

  @media only screen and (min-width: 992px) {
    .container {
      width: ${GridConfigs.lg.containerWidth};
      max-width: ${GridConfigs.lg.containerMaxWidth};
      padding-right: ${GridConfigs.lg.gap};
      padding-left: ${GridConfigs.lg.gap};
    }

    .row {
      margin-right: -${GridConfigs.lg.gap};
      margin-left: -${GridConfigs.lg.gap};
    }

    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-offset-0,
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12{
      padding-right: ${GridConfigs.lg.gap};
    padding-left: ${GridConfigs.lg.gap};
    }
  }

  @media only screen and (min-width: 1200px) {
    .container {
      width: ${GridConfigs.xl.containerWidth};
      max-width: ${GridConfigs.xl.containerMaxWidth};
      padding-right: ${GridConfigs.xl.gap};
      padding-left: ${GridConfigs.xl.gap};
    }

    .row {
      margin-right: -${GridConfigs.xl.gap};
      margin-left: -${GridConfigs.xl.gap};
    }

    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-offset-0,
    .col-xl-offset-1,
    .col-xl-offset-2,
    .col-xl-offset-3,
    .col-xl-offset-4,
    .col-xl-offset-5,
    .col-xl-offset-6,
    .col-xl-offset-7,
    .col-xl-offset-8,
    .col-xl-offset-9,
    .col-xl-offset-10,
    .col-xl-offset-11,
    .col-xl-offset-12 {
      padding-right: ${GridConfigs.xl.gap};
    padding-left: ${GridConfigs.xl.gap};
    }
  }
`;
