import React, { MouseEventHandler } from 'react';

// import { ActivityIndicator } from '@atomic/atm.activity-indicator/activity-indicator.component';
import {
  ButtonContentStyled,
  ButtonSpinnerStyled,
  ButtonWrapperStyled,
  ButtonWrapperStyledProps
} from './button.component.style';

interface ButtonProps extends ButtonWrapperStyledProps {
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  textAlign?: string;
  disabled?: boolean;
  newTab?: boolean;
  clicked?: boolean;
  hovered?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { loading, ...other } = props;

  if (props.href && !props.disabled) {
    return (
      <ButtonWrapperStyled {...other}>{props.children}</ButtonWrapperStyled>
    );
  }

  return (
    <ButtonWrapperStyled {...props}>
      <ButtonContentStyled processing={loading}>
        {props.children}
      </ButtonContentStyled>
      <ButtonSpinnerStyled processing={loading}>
        {/* <ActivityIndicator /> */}
      </ButtonSpinnerStyled>
    </ButtonWrapperStyled>
  );
};
