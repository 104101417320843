import styled from 'styled-components';

import { Col } from '@atomic/atm.grid';
import {
  Color,
  FontFamily,
  FontWeight,
  LineHeight,
  Spacing,
  FontSize,
  Shadow,
  Breakpoint
} from '@atomic/constants';

export const MenuDesktopStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  width: 100%;

  padding: ${Spacing.SemiSmall} 0;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  border-bottom: 2px solid ${Color.Transparent};
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom-color: ${Color.Gray200};
  }

  @media (max-width: ${Breakpoint.XSmall}) {
    .container {
      width: 90%;
    }
  }
`;

export const LogoWrapperStyled = styled.div`
  z-index: 1000;
  max-width: 100%;

  & > * {
    max-width: 100%;
    max-height: ${Spacing.Large};
  }
`;

export const NavigationContentStyled = styled.div`
  z-index: 999;

  a {
    text-decoration: none;
    margin-right: ${Spacing.XNano};
  }

  button {
    margin-left: ${Spacing.SemiSmall};
  }

  button + a {
    width: fit-content;
    box-sizing: border-box;
    margin-right: 0;
    margin-left: ${Spacing.Small};

    button {
      margin-left: 0;
    }
  }

  @media (max-width: ${Breakpoint.Medium}) {
    & {
      position: fixed;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: auto;

      visibility: hidden;
      opacity: 0;

      box-sizing: border-box;

      padding: 0 calc((100vw - 640px) / 2) ${Spacing.Medium}
        calc((100vw - 640px) / 2);
      padding-top: ${Spacing.XLarge};

      background-color: ${Color.White};
      box-shadow: ${Shadow.Primary};

      transform: translateY(-5vh);
      transition: all 0.4s ease-out;

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      a,
      button {
        display: block;

        width: 100%;

        margin-left: 0;
        margin-right: 0;

        box-sizing: border-box;
      }

      a {
        width: calc(100% + (${Spacing.SemiSmall} * 2));
        margin-left: -${Spacing.SemiSmall};
        margin-bottom: ${Spacing.Nano};
      }

      button {
        margin-top: ${Spacing.Medium};

        & + a {
          width: 100%;
          margin: 0;
          margin-top: ${Spacing.Small};

          button {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: ${Breakpoint.Small}) {
    & {
      padding-left: calc((100vw - 560px) / 2);
      padding-right: calc((100vw - 560px) / 2);
    }
  }

  @media (max-width: 610px) and (min-width: calc(${Breakpoint.Small} + 1px)) {
    & {
      padding-left: calc(2.5vw + 8px);
      padding-right: calc(2.5vw + 8px);
    }
  }

  @media (max-width: ${Breakpoint.Small}) {
    & {
      padding-left: calc(5vw + 8px);
      padding-right: calc(5vw + 8px);
    }
  }
`;

export const BtnHamburguerStyled = styled.button`
  z-index: 1000;

  padding: ${Spacing.Small} 0 ${Spacing.Small} ${Spacing.SemiSmall};

  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  font-weight: ${FontWeight.Medium};

  line-height: ${LineHeight.XSmall};

  background-color: ${Color.Transparent};
  color: ${Color.Gray800};

  text-decoration: none;
  transition: all 0.2 ease-out;

  display: none;

  @media (max-width: 992px) {
    & {
      display: inline-flex;
      align-items: center;
    }
  }

  span {
    width: 20px;

    margin-left: ${Spacing.Nano};

    border-top: 2px solid ${Color.Gray700};
    transition: all 0.2 ease-out;

    &::after,
    &::before {
      content: '';
      position: relative;
      display: block;

      width: 20px;
      height: 2px;

      background: ${Color.Gray700};
      margin-top: 5px;
      transition: 0.3s;
    }
  }
  &.active span {
    border-top-color: transparent;
  }
  &.active span::before {
    transform: rotate(135deg);
  }
  &.active span::after {
    transform: rotate(-135deg);
    top: -7px;
  }
`;

export const MenuContentStyled = styled(Col)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
