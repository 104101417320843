import { Spacing } from '@atomic/constants';

export const GridConfigs = {
  xs: {
    containerWidth: '90%',
    containerMaxWidth: '90%',
    gap: `${Spacing.XNano}`
  },
  sm: {
    containerWidth: '560px',
    containerMaxWidth: '90%',
    gap: `${Spacing.Nano}`
  },
  md: {
    containerWidth: '640px',
    containerMaxWidth: '90%',
    gap: `${Spacing.Nano}`
  },
  lg: {
    containerWidth: '976px',
    containerMaxWidth: '85%',
    gap: `${Spacing.Small}`
  },
  xl: {
    containerWidth: '1184px',
    containerMaxWidth: '80%',
    gap: `${Spacing.SemiSmall}`
  }
};
