import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import {
  BrandColor,
  Color,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing
} from '@atomic/constants';

interface H1Props {
  white?: boolean;
  center?: boolean;
}

export const H1 = styled(motion.h1)<H1Props>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XXLarge};
  line-height: ${LineHeight.Small};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  color: ${(props) => (props.white ? Color.Gray300 : Color.Gray900)};
  display: block;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

interface H2Props {
  white?: boolean;
  center?: boolean;
}

export const H2 = styled(motion.h2)<H2Props>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XLarge};
  color: ${(props) => (props.white ? Color.White : Color.Gray900)};
  line-height: ${LineHeight.Medium};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  display: block;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

interface H3Props {
  white?: boolean;
  expanded?: boolean;
}

export const H3 = styled(motion.h3)<H3Props>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Large};
  line-height: ${LineHeight.Medium};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  color: ${(props) => (props.white ? Color.White : Color.Gray900)};
  ${(props) => props.expanded && 'display: block;width: 100%;'}
`;

interface H4Props {
  white?: boolean;
}
export const H4 = styled(motion.h4)<H4Props>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Medium};
  line-height: ${LineHeight.Large};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  color: ${(props: BodyProps) => (props.white ? Color.Gray300 : Color.Gray900)};
`;

interface H5Props {
  center?: boolean;
}

export const H5 = styled(motion.h5)<H5Props>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  line-height: ${LineHeight.Large};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Regular};
  color: ${Color.Gray700};
  text-align: ${(props) => (props.center ? 'center; width: 100%' : 'left')};
`;

export const H5Enhance = styled(H5)`
  color: ${Color.Gray900};
  font-weight: ${FontWeight.Medium};
`;

export const H6 = styled(motion.h6)`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XSmall};
  line-height: ${LineHeight.Large};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Regular};
  color: ${Color.Gray700};
`;

export const H6Enhance = styled(H6)`
  color: ${Color.Gray900};
  font-weight: ${FontWeight.Medium};
`;

export const InputValueCss = css`
  color: ${Color.Gray900};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Regular};
  line-height: ${LineHeight.XSmall};
`;

export const InputValue = styled.label`
  ${InputValueCss};
`;

export const InputDisabledCss = css`
  color: ${Color.Gray700};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Bold};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${Color.Gray700};
  opacity: 1;
  -webkit-opacity: 1;
`;

export const InputDisabled = styled.label`
  ${InputDisabledCss};
`;

interface InputLabelProps {
  hasError?: boolean;
}

export const InputLabel = styled.label<InputLabelProps>`
  color: ${(props) => (props.hasError ? Color.Alert : Color.Gray900)};
  display: block;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  line-height: ${LineHeight.XSmall};
  margin-bottom: ${Spacing.Nano};
  text-align: left;
`;

export const InputPlaceholderCss = css`
  color: ${Color.Gray600};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
`;

export const InputCaption = styled.label`
  color: ${Color.Gray600};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.Small};
  display: block;
  text-align: left;
`;

export const InputCaptionError = styled(InputCaption)`
  color: ${Color.Alert};
  font-size: ${FontSize.XSmall};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};

  &::before {
    content: '✖ ';
    font-size: ${FontSize.XSmall};
  }
`;

interface BodyProps {
  white?: boolean;
  textAlign?: 'center' | 'left' | 'right';
}
export const Body = styled(motion.p)<BodyProps>`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Medium};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Regular};
  color: ${(props: BodyProps) => (props.white ? Color.Gray300 : Color.Gray700)};
  line-height: ${LineHeight.Large};
  text-align: ${(props) => props.textAlign};

  & + & {
    margin-top: ${Spacing.SemiSmall};
  }
`;

export const BodyLink = styled(Link)`
  font-family: ${FontFamily.Primary};
  font-size: 1em;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Medium};
  color: ${BrandColor.Brand500};
  cursor: pointer;
`;

interface EnhanceProps {
  align?: string;
  dark?: boolean;
}

export const Enhance = styled.strong<EnhanceProps>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${FontWeight.Bold};
  color: ${(props) => (props.dark ? Color.Gray900 : 'inherit')};
`;
