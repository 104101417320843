import styled from 'styled-components';

import {
  Color,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Radius,
  Spacing
} from '@atomic/constants';

enum ButtonKind {
  callToAction = 'callToAction',
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary'
}

export interface ButtonWrapperStyledProps {
  expanded?: boolean;
  vExpanded?: boolean;
  outlined?: boolean;
  kind?: keyof typeof ButtonKind | 'primary';
  variant?: 'solid' | 'outline' | 'link';
  disabled?: boolean;
  type?: string;
  textAlign?: string;
  clicked?: boolean;
  hovered?: boolean;
}

type ButtonColor = {
  [key in ButtonKind]?: {
    textColor?: string;
    shapeColor?: string;

    clickedShapeColor?: string;
    clickedTextColor?: string;

    hoverShapeColor?: string;
    hoverTextColor?: string;
  };
};

const buttonColor: ButtonColor = {
  [ButtonKind.callToAction]: {
    textColor: Color.White,
    shapeColor: Color.Gray900,

    hoverTextColor: Color.White,
    hoverShapeColor: Color.Gray800,

    clickedTextColor: Color.White,
    clickedShapeColor: Color.Gray700
  },
  [ButtonKind.primary]: {
    textColor: Color.Gray800,
    shapeColor: Color.Gray100,

    hoverTextColor: Color.Gray800,
    hoverShapeColor: Color.Gray200,

    clickedTextColor: Color.Gray900,
    clickedShapeColor: Color.Gray400
  },
  [ButtonKind.secondary]: {
    textColor: Color.White,
    shapeColor: Color.Gray900,

    hoverTextColor: Color.White,
    hoverShapeColor: Color.Gray800,

    clickedTextColor: Color.White,
    clickedShapeColor: Color.Gray700
  },
  [ButtonKind.tertiary]: {
    textColor: Color.Gray800,
    shapeColor: Color.Transparent,

    hoverTextColor: Color.Gray800,
    hoverShapeColor: Color.Transparent,

    clickedTextColor: Color.Gray900,
    clickedShapeColor: Color.Transparent
  }
};

export const ButtonWrapperStyled = styled.button<ButtonWrapperStyledProps>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.expanded ? '100%' : 'fit-content')};
  height: ${(props) => props.vExpanded && '100%'};
  border: none;

  padding: ${Spacing.SemiSmall} ${Spacing.SemiLarge};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  font-weight: ${FontWeight.Medium};

  line-height: ${LineHeight.XSmall};

  text-decoration: none;
  text-transform: capitalize;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  color: ${(props) => buttonColor[props.kind].textColor};
  border-radius: ${Radius.Small};
  background-color: ${(props) => buttonColor[props.kind].shapeColor};

  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;

  a {
    box-sizing: border-box;
    width: 100%;
  }

  ${(props) =>
    props.kind === 'primary' &&
    `border: 1px solid ${Color.Gray400};
`};

  ${(props) =>
    props.kind === 'secondary' &&
    `padding: ${Spacing.Small} ${Spacing.SemiSmall};
`};

  ${(props) =>
    props.kind === 'tertiary' &&
    `padding: ${Spacing.Small} ${Spacing.SemiSmall};
    border: 1px solid ${Color.Gray400};
`};

  ${(props) =>
    props.hovered &&
    `background-color: ${buttonColor[props.kind].hoverShapeColor};
     color: ${buttonColor[props.kind].hoverTextColor};
     border-color: ${buttonColor.tertiary.hoverTextColor};
     `}

  &:focus-within {
    outline: 0;
  }

  &:hover {
    background-color: ${(props) => buttonColor[props.kind].hoverShapeColor};
    color: ${(props) => buttonColor[props.kind].hoverTextColor};
    border-color: ${(props) => buttonColor[props.kind].hoverTextColor};
  }

  ${(props) =>
    props.clicked &&
    `background-color: ${buttonColor[props.kind].clickedShapeColor};
     color: ${buttonColor[props.kind].clickedTextColor};
     border-color: ${buttonColor.tertiary.clickedTextColor};
`}

  ${(props: ButtonWrapperStyledProps) => props.expanded && 'width: 100%;'}
  ${(props: ButtonWrapperStyledProps) => {
    switch (props.variant) {
      case 'outline':
        return `
          background-color: transparent;
          color: ${buttonColor[props.kind].shapeColor};
        `;
      case 'link':
        return `
          padding: 0;
          border: 0;
          background-color: transparent;
          color: ${buttonColor[props.kind].shapeColor};
        `;
      default:
        return '';
    }
  }}

  ${(props: ButtonWrapperStyledProps) =>
    props.disabled &&
    `
      cursor: not-allowed;
      opacity: 0.7;
    `}
`;

interface ButtonContentProps {
  processing: boolean;
}

export const ButtonContentStyled = styled.div<ButtonContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.processing ? 0 : 1)};
  font-stretch: 100%;

  transition: all 0.2s ease-in-out;
`;

export const ButtonSpinnerStyled = styled.span<ButtonContentProps>`
  position: absolute;
  right: calc(50% - 8px);
  top: calc(50% - 10px);

  display: ${(props) =>
    props.processing ? 'inline-block' : 'none'} !important;
`;
