import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  Color,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
  Radius
} from '@atomic/constants';

export const NavigationLinkStyled = styled(Link)`
  padding: ${Spacing.Small} ${Spacing.SemiSmall};

  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  font-weight: ${FontWeight.Medium};
  line-height: ${LineHeight.XSmall};

  text-decoration: none;
  transition: 0.2 ease-out;

  color: ${Color.Gray800};

  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: ${Color.Gray200};
    border-radius: ${Radius.Small};
  }
`;
